<template>
  <v-row v-if="warehouseFilters" class="flex-column flex-md-row my-5 my-md-0">
    <v-col
      cols="12"
      sm="auto"
      md="3"
      :key="warehouseFilter.id"
      v-for="warehouseFilter in warehouseFilters"
    >
      <v-select
        class="default--text"
        dense
        clearable
        single-line
        outlined
        hide-details
        :items="warehouseFilter.values"
        :label="warehouseFilter.label"
        v-model="warehouseClassToFilter"
        item-text="name"
        item-value="warehouseClassId"
        @change="handleFilterResults"
      ></v-select>
    </v-col>
  </v-row>
</template>

<script>
import AddressService from "~/service/addressService";
export default {
  name: "WarehouseClassFilter",

  data() {
    return {
      warehouseClassToFilter: null,
      warehouseFilters: null,
      warehouseList: null
    };
  },
  props: {
    warehouses: {
      type: Array,
      required: true
    }
  },
  computed: {
    /*warehouseFiltersLength() {
      return 12 / this.warehouseFilters.length;
    }*/
  },

  methods: {
    getWarehouseFilters() {
      console.log("ENTETED");
      let warehouseGroups = {};

      for (const warehouse of this.warehouses) {
        if (warehouse.warehouseClass) {
          for (const warehouseClass of warehouse.warehouseClass) {
            const groupId =
              warehouseClass.warehouseClassGroup.warehouseClassGroupId;
            const id = warehouseClass.warehouseClassGroup.warehouseClassGroupId;
            const label = warehouseClass.warehouseClassGroup.name;

            if (!warehouseGroups[groupId]) {
              warehouseGroups[groupId] = { id, label, values: [] };
            }
            const newValue = {
              warehouseClassId: warehouseClass.warehouseClassId,
              name: warehouseClass.name
            };

            const existingValue = warehouseGroups[groupId].values.find(
              value => value.warehouseClassId === newValue.warehouseClassId
            );

            if (!existingValue) {
              warehouseGroups[groupId].values.push(newValue);
            }
          }
        }
      }

      return Object.values(warehouseGroups);
    },
    async handleFilterResults() {
      const searchParams = {
        warehouse_class_id: [this.warehouseClassToFilter]
      };
      let data = await AddressService.findWarehouse(searchParams);
      this.warehouseList = data.warehouses;
      this.$emit("filter-warehouses", this.warehouseList);
    }
  },
  mounted() {
    this.warehouseFilters = this.getWarehouseFilters();
  }
};
</script>
