<template>
  <!-- <div class="address-filter-container" v-if="googleEnabled"> -->
  <div class="address-filter-container">
    <div class="d-flex w-100">
      <!-- <vue-google-autocomplete
        id="pac-input"
        classname="google-autocomplete-input address-to-search"
        ref="addresstosearchref"
        :placeholder="$t('warehouse.filter.searchLabel')"
        v-on:placechanged="getAddressData"
        :value="baseAddressString"
        :enable-geolocation="true"
        country="it"
        v-if="googleEnabled"
      >
      </vue-google-autocomplete> -->
      <v-text-field
        clearable
        outlined
        id="pac-input"
        class="address-to-search rounded-pill white"
        label="Ricerca negozio"
        hide-details="auto"
        v-model="warehouseName"
        @change="getAddressData"
        @click.clear="resetNearWarehouse"
      ></v-text-field>

      <!-- <v-btn
        class="geolocate-btn"
        color="primary"
        icon
        @click="resetNearWarehouse()"
        v-if="showNearWarehouse"
      >
        <v-icon>$close</v-icon>
      </v-btn> -->
      <!-- <v-btn
        class="geolocate-btn"
        color="primary"
        icon
        @click="geolocate()"
        v-else
      >
        <v-icon>$myLocation</v-icon>
      </v-btn> -->
    </div>
    <!-- <div
      class="d-flex align-start align-sm-center flex-column flex-sm-row mt-4 mt-sm-0 filter-text"
    >
      Raggio di ricerca:
      <v-radio-group
        class="ml-1"
        dense
        v-model="distanceRange"
        row
        @change="distanceRangeChanged()"
      >
        <v-radio color="primary" :value="10000">
          <template v-slot:label>
            <span class="distance-label distance-radio-label">
              10km
            </span>
          </template>
        </v-radio>
        <v-radio color="primary" :value="30000">
          <template v-slot:label>
            <span class="distance-label distance-radio-label">
              30km
            </span>
          </template>
        </v-radio>
        <v-radio color="primary" :value="60000">
          <template v-slot:label>
            <span class="distance-label distance-radio-label">
              60km
            </span>
          </template>
        </v-radio>
      </v-radio-group>
    </div> -->
  </div>
</template>
<style lang="scss">
.address-filter-container {
  .google-autocomplete-input-container {
    display: flex;

    border: 1px solid var(--v-grey-base);
    padding: 3px 6px 3px 15px;
    background: #ffffff;
    font-size: 14px;
    .google-autocomplete-input {
      width: inherit;
      &:focus {
        outline: none;
      }
    }
  }
}
</style>
<script>
// import VueGoogleAutocomplete from "vue-google-autocomplete";
// import AddressService from "~/service/addressService";
// import gmapsInit from "@/components/buttons/gmaps.js";
// import includes from "lodash/includes";

// var google;

export default {
  name: "AddressFilter",
  components: {},
  props: {
    selectedDeliveryServiceId: { type: Number, default: 1 }
  },
  data() {
    return {
      baseAddressString: null,
      distanceRange: 10000,
      addressToSearch: null,
      showNearWarehouse: false,
      googleEnabled: false,
      internalValue: {},
      warehouseName: ""
    };
  },
  computed: {},
  methods: {
    getAddressData() {
      this.$emit("addressToSearchChanged", this.warehouseName);
    },
    // async getAddressData(addressData, placeResult) {
    //   let vm = this;
    //   placeResult.address_components.forEach(element => {
    //     if (element.types[0] == "administrative_area_level_2") {
    //       vm.internalValue.province = element.short_name;
    //       return;
    //     }
    //     if (element.types[0] == "country") {
    //       vm.internalValue.country = element.short_name;
    //       return;
    //     }
    //   });
    //   try {
    //     this.dataValidated = await AddressService.isValidAddress({
    //       country: vm.internalValue.country
    //         ? { iso: vm.internalValue.country }
    //         : undefined,
    //       city: addressData.locality,
    //       postalcode: addressData.postal_code,
    //       address1: addressData.route,
    //       addressNumber: addressData.street_number,
    //       gpsLongitude: addressData.longitude,
    //       gpsLatitude: addressData.latitude,
    //       longitude: addressData.longitude,
    //       latitude: addressData.latitude,
    //       province: this.getProvince(placeResult)
    //     });
    //     this.deliveryServiceAvailable = true;
    //   } catch (err) {
    //     this.deliveryServiceAvailable = false;
    //   }
    //   //lookup drive
    //   try {
    //     this.warehouses = await AddressService.getAddressByDeliveryService(
    //       1,
    //       addressData.latitude,
    //       addressData.longitude,
    //       global.config.proximityDefaultDistance
    //     );
    //   } catch (err) {
    //     console.log(err);
    //   }
    //   this.results = true;
    // },

    // getProvince(placeResult) {
    //   let area = placeResult.address_components.find(p => {
    //     return includes(p.types, "administrative_area_level_2");
    //   });
    //   return area["short_name"];
    // },
    resetNearWarehouse() {
      this.showNearWarehouse = false;
      this.addressToSearch = null;
      document.getElementById("pac-input").value = "";
      this.$emit("resetNearWarehouse");
    },
    emitSearchAddress() {
      this.showNearWarehouse = true;
      this.$emit("addressToSearchChanged", this.addressToSearch);
    },
    distanceRangeChanged() {
      if (this.addressToSearch) {
        this.addressToSearch.distance = this.distanceRange;
        this.emitSearchAddress();
      }
    },
    addressToSearchChanged(address) {
      this.addressToSearch = {
        latitude: address.latitude,
        longitude: address.longitude,
        distance: this.distanceRange
      };
      this.emitSearchAddress();
    }
    // geolocate() {
    //   let vm = this;
    //   if (navigator.geolocation) {
    //     document.getElementById("pac-input").value = "";
    //     document.getElementById("pac-input").placeholder =
    //       "Localizzazione in corso...";
    //     navigator.geolocation.getCurrentPosition(
    //       function(position) {
    //         var latlng = new vm.google.maps.LatLng(
    //           position.coords.latitude,
    //           position.coords.longitude
    //         );
    //         let addressData = null;
    //         var geocoder = new vm.google.maps.Geocoder();
    //         geocoder.geocode({ location: latlng }, function(results, status) {
    //           if (status == vm.google.maps.GeocoderStatus.OK) {
    //             addressData = results[0];
    //             vm.addressToSearch = {
    //               latitude: addressData.geometry.location.lat(),
    //               longitude: addressData.geometry.location.lng(),
    //               distance: vm.distanceRange
    //             };
    //             document.getElementById("pac-input").placeholder = vm.$t(
    //               "navbar.service." +
    //                 vm.selectedDeliveryServiceId +
    //                 ".searchLabel"
    //             );
    //             document.getElementById("pac-input").value =
    //               addressData.formatted_address;
    //             vm.emitSearchAddress();
    //           } else {
    //             console.log(
    //               "Geocode was not successful for the following reason: "
    //             );
    //           }
    //         });
    //       },
    //       function(err) {
    //         console.log(err);
    //         alert(
    //           "è necessario abilitare la localizzazione per utilizzare questa funzione"
    //         );
    //       },
    //       { enableHighAccuracy: false, maximumAge: Infinity }
    //     );
    //   }
    // }
  },
  watch: {
    warehouseName: function() {
      this.getAddressData();
    }
  }
  // async mounted() {
  //   try {
  //     google = await gmapsInit(global.config.google_api_key);
  //     if (google) {
  //       this.googleEnabled = true;
  //       this.$nextTick(() => {
  //         if (this.additionalData) {
  //           if (this.additionalData.addressString) {
  //             this.baseAddressString = this.additionalData.addressString;
  //           }
  //           if (
  //             this.additionalData.addressData &&
  //             this.additionalData.placeResult
  //           ) {
  //             this.getAddressData(
  //               this.additionalData.addressData,
  //               this.additionalData.placeResult
  //             );
  //           }
  //         }
  //       });
  //     }
  //   } catch (error) {
  //     throw new Error("Error Loading GMAPS");
  //   }
  // }
};
</script>
