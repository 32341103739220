<template>
  <div class="warehouse-map pt-4">
    <div
      class="flyer-title-container"
      v-if="user && user.defaultStoreAddressId"
    >
      <h1
        class="d-inline grey lighten-3 px-2"
        v-html="$t('flyers.title', { warehouse: '_TODO_' })"
      ></h1>
      <v-divider class="custom-divider"></v-divider>
    </div>
    <v-container>
      <!-- <div class="flyers-container" v-if="user && user.defaultStoreAddressId">
        <FlyerContainer
          :warehouseId="user.defaultStoreAddressId"
        ></FlyerContainer>
      </div> -->
      <div @click.prevent.stop="handleClick()">
        <category-block
          :target="category"
          position="position1"
          class="category-block category-block-1"
        />
      </div>
      <div class="warehouse-list" v-if="warehouseList">
        <div
          class="pre-header d-flex flex-column flex-md-row justify-space-between align-center pb-4"
        >
          <div class="text-left">
            <h2 class="mt-4">
              {{ $t("warehouse.list.subtitle") }}
            </h2>
            <p class="mb-4">{{ $t("warehouse.list.subtitle2") }}</p>
          </div>
          <!-- <router-link
            class="text-h2 primary--text font-weight-bold"
            v-if="
              isAuthenticated &&
                this.user &&
                this.user.defaultStoreAddress &&
                this.user.defaultStoreAddress.addressId
            "
            :to="{
              name: 'WarehouseDetail',
              params: { slug: this.user.defaultStoreAddress.addressId }
            }"
            >{{ $t("warehouse.goToFavWarehouse") }}
            <v-icon color="primary">$chevronRight</v-icon></router-link
          > -->
        </div>
        <v-row
          no-gutters
          class="py-4 px-2 accent align-center flex-column flex-md-row"
        >
          <v-col cols="12" md="4">
            <AddressFilter
              @addressToSearchChanged="onFilterAddress"
              @resetNearWarehouse="resetNearWarehouse"
          /></v-col>
          <v-col cols="12" md="7" class="mx-auto" :key="warehouse_key">
            <WarehouseClassFilter
              :warehouses="warehouseList"
              @filter-warehouses="onFilterWarehouses"
            ></WarehouseClassFilter>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col
            v-if="showListParsed"
            class="py-0"
            order="2"
            order-sm="1"
            cols="12"
            :sm="showListParsed ? '12' : '0'"
            :md="showListParsed ? '12' : '0'"
            :xl="showListParsed ? '12' : '0'"
          >
            <v-list class="warehouse-list-list" :height="mapHeight">
              <v-list-item
                v-for="warehouse in warehouseList"
                class="warehouse-row px-2"
                :key="warehouse.warehouseId"
                two-line
                dense
                @click="toggleInfoWindow(warehouse)"
              >
                <v-list-item-content>
                  <v-row no-gutters class="align-center justify-space-between">
                    <v-col cols="12" md="10" sm="12">
                      <v-list-item-title
                        class="warehouse-title text-left font-weight-bold text-uppercase primary--text mb-2"
                      >
                        {{ warehouse.name || warehouse.address.addressName }}
                      </v-list-item-title>
                      <v-list-item-subtitle class="warehouse-address">
                        {{ warehouse.address.address1 }},{{
                          warehouse.address.addressNumber
                        }}
                        {{ warehouse.address.city }} ({{
                          warehouse.address.province
                        }})
                      </v-list-item-subtitle></v-col
                    >
                    <v-col
                      cols="12"
                      md="2"
                      xs="12"
                      class="warehouse-btn-info py-sm-4 py-md-0 d-flex justify-end"
                      ><v-btn
                        x-small
                        elevation="0"
                        color="primary"
                        :to="{
                          name: 'WarehouseDetail',
                          params: { slug: warehouse.slug }
                        }"
                        >INFO NEGOZIO</v-btn
                      >
                      <v-btn
                        @click="clicked"
                        x-small
                        elevation="0"
                        color="primary"
                        class="text-uppercase mx-4"
                        :href="
                          `https://www.google.it/maps/dir//${warehouse.name}/@${warehouse.address.latitude},${warehouse.address.longitude}`
                        "
                        target="_blank"
                        >{{ $t("warehouse.detail.directions") }}</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
          <v-col
            class="py-0"
            order="1"
            order-sm="2"
            cols="12"
            :sm="showListParsed ? '7' : '0'"
            :xl="showListParsed ? '8' : '0'"
          >
            <!-- GmapMap
              ref="mapRef"
              :center="mapInitialPosition"
              :zoom="zoomLevel"
              :style="{ width: mapWidth, height: mapHeight }"
              :options="{
                zoomControl: true,
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: false,
                disableDefaultUI: false
              }"
              @click="clicked"
            >
              <GmapMarker
                :ref="'warehousMarker_' + warehouse.warehouseId"
                v-for="(warehouse, i) in warehouseList"
                :key="warehouse.warehouseId"
                :position="
                  google &&
                    new google.maps.LatLng(
                      warehouse.address.latitude,
                      warehouse.address.longitude
                    )
                "
                :clickable="true"
                icon="/img_layout/map/negozi.png"
                @click="toggleInfoWindow(warehouse, i)"
              />
              <gmap-info-window
                :options="infoOptions"
                :position="infoWindowPos"
                :opened="infoWinOpen"
                @closeclick.stop="infoWinOpen = false"
              >
                {{ selectedWarehouse }} Contenuto
              </gmap-info-window>
              <gmap-circle
                v-if="addressToSearch"
                :center="{
                  lat: addressToSearch.latitude,
                  lng: addressToSearch.longitude
                }"
                :radius="addressToSearch.distance"
                :visible="true"
                :options="{ fillOpacity: 0.3, strokeWeight: 0.3 }"
              >
              </gmap-circle>
            </GmapMap -->
          </v-col>
        </v-row>
        <!-- <h2 class="text-center mt-4">{{ $t("warehouse.list.subtitle3") }}</h2>
        <p class="text-center mb-4">{{ $t("warehouse.list.subtitle4") }}</p>
        <v-row>
          <v-col
            cols="12"
            md="3"
            v-for="province in provinces"
            :key="province.categoryId"
          >
            <v-card
              outlined
              class="rounded-md text-center d-flex flex-column align-center pb-2"
            >
              <v-img
                :src="province.metaData.category_info?.HEADER_IMAGE"
                height="150"
              ></v-img>
              <v-card-title class="pa-1 mb-n2"
                ><h3>{{ province.name }}</h3></v-card-title
              >
              <v-card-text class="pa-1"
                >Scopri tutti i negozi Alí presenti nella provincia di
                {{ province.name }}.</v-card-text
              >
              <v-card-actions>
                <v-btn
                  elevation="0"
                  class="primary rounded-pill"
                  :to="`/${province.slug}`"
                  >Scopri i negozi</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row> -->
      </div>
    </v-container>
  </div>
</template>

<style lang="scss">
.warehouse-map {
  h1 {
    z-index: 2;
    position: relative;
    margin: 0;
    padding: 0;
    margin-left: 8%;
    span {
      font-weight: 400;
      position: relative;
      font-size: 16px;
      top: -4px;
    }
  }
  .go-back {
    text-decoration: none;
    cursor: pointer;
  }
  .custom-divider {
    border-width: 1px;
    border-color: var(--v-primary-base);
    position: absolute;
    width: 100vw;
    top: 36px;
    z-index: 1;
  }

  .warehouse-list-list {
    .warehouse-map {
      h1 {
        z-index: 2;
        position: relative;
        margin: 0;
        padding: 0;
        margin-left: 8%;
        span {
          font-weight: 400;
          position: relative;
          font-size: 16px;
          top: -4px;
        }
      }
      .go-back {
        text-decoration: none;
        cursor: pointer;
      }
      .custom-divider {
        border-width: 1px;
        border-color: var(--v-primary-base);
        position: absolute;
        width: 100vw;
        top: 36px;
        z-index: 1;
      }
      .warehouse-list-list {
        &-list {
          height: 380px;
          @media #{map-get($display-breakpoints, 'xs-only')} {
            height: initial;
          }
          overflow-y: auto;
          .warehouse-address {
            line-height: initial;
          }
          .warehouse-title,
          .warehouse-address {
            font-size: 14px;
            @media #{map-get($display-breakpoints, 'xs-only')} {
              white-space: normal;
            }
          }
          .warehouse-row {
            border-bottom: 2px solid var(--v-grey-lighten1);
          }
        }
        &-reset {
          @media only screen and (max-width: 959px) {
            width: 100%;
          }
        }
      }
      .gm-style-iw .gm-style-iw {
        max-height: 180px !important;
      }
      .gm-style {
        .gm-style-iw-c {
          max-height: 180px !important;
          .gm-style-iw-d {
            max-height: 180px !important;
          }
        }
      }
    }
    height: 380px;
    @media #{map-get($display-breakpoints, 'xs-only')} {
      height: initial;
    }
    overflow-y: auto;
    .warehouse-address {
      line-height: initial;
    }
    .warehouse-title,
    .warehouse-address {
      font-size: 14px;
      @media #{map-get($display-breakpoints, 'xs-only')} {
        white-space: normal;
      }
    }
  }
  .warehouse-row {
    border-bottom: 2px solid var(--v-grey-lighten1);
    .warehouse-btn-info {
      @media #{map-get($display-breakpoints, 'sm-and-down')} {
        display: flex;
        justify-content: flex-end;
        margin-top: 8px;
      }
    }
  }
  .gm-style-iw .gm-style-iw {
    max-height: 180px !important;
  }
  .gm-style {
    .gm-style-iw-c {
      max-height: 180px !important;
      .gm-style-iw-d {
        max-height: 180px !important;
      }
    }
  }
}
</style>
<script>
import AddressFilter from "@/components/address/AddressFilter.vue";
import WarehouseClassFilter from "@/components/warehouse/WarehouseClassFilter.vue";
import AddressService from "~/service/addressService";
import category from "@/commons/mixins/category";
import clickHandler from "~/mixins/clickHandler";
import { mapGetters } from "vuex";
import login from "~/mixins/login";

import { mapState } from "vuex";
// import FlyerContainer from "@/components/shopfully/FlyerContainer.vue";

export default {
  name: "WarehouseMap",
  components: { AddressFilter, WarehouseClassFilter },
  mixins: [category, clickHandler, login],
  props: {
    showList: { type: String, default: "true" },
    regionId: { type: String, required: false },
    provinceId: { type: String, required: false },
    parentAddressClassId: { type: String, required: false },
    showLocker: { type: String, default: "false" }
  },
  data() {
    return {
      warehouse_key: 1,
      showListParsed: true,
      warehouseList: [],
      warehouseListOriginal: [],
      infoOptions: {
        content: "",
        pixelOffset: {
          width: 0,
          height: -35
        }
      },
      infoWindowPos: null,
      infoWinOpen: false,
      selectedWarehouse: null,
      mapInitialPosition: { lat: 45.410119194768654, lng: 11.881289090441028 },
      addressToSearch: null,
      provinces: null,
      filters: null,
      selectedFilter: null,
      filtersIds: [14, 15, 16],
      defaultStoreAddressId: null
    };
  },
  computed: {
    ...mapGetters({
      isAuthenticated: "cart/isAuthenticated"
    }),

    mapWidth() {
      return "auto";
    },
    mapHeight() {
      return this.$vuetify.breakpoint.xsOnly ? "300px" : "465px";
    },
    pageContent() {
      return this.page && this.page.content ? this.page.content.rendered : "";
    },
    pageTitle() {
      return this.page && this.page.title ? this.page.title.rendered : "";
    },
    zoomLevel() {
      if (this.addressToSearch) {
        return this.addressToSearch.distance == 10000 ? 12 : 10;
      } else {
        return this.$vuetify.breakpoint.xsOnly ? 8 : 9;
      }
    },
    ...mapState({
      user: ({ cart }) => cart.cart.user
    })
  },
  methods: {
    resetNearWarehouse() {
      this.addressToSearch = null;
    },
    async handleClick() {
      if (
        this.isAuthenticated &&
        this.user &&
        this.user.defaultStoreAddress &&
        this.user.defaultStoreAddress.addressId
      ) {
        let wh = this.warehouseListOriginal.filter(
          wh => this.user.defaultStoreAddress.addressId == wh.address.addressId
        );
        this.$router.push({
          name: "WarehouseDetail",
          params: {
            slug:
              wh[0].warehouseId != null
                ? wh[0].warehouseId
                : this.user.defaultStoreAddressId
          }
        });
      } else {
        /* let popupConfig = {};
        popupConfig.DESCRIPTION =
          "Se hai già un account, accedi al sito.<br/>" +
          "Se non possiedi ancora un account, consulta la lista dei punti vendita.";
        await global.vm.$dialog.show(Popup, {
          popup: popupConfig
        }); */
        this.needLogin("navbar");

        global.EventBus.$emit("showMessageLogin");

        // if (
        //   this.isAuthenticated &&
        //   this.user &&
        //   this.user.defaultStoreAddress &&
        //   this.user.defaultStoreAddress.addressId
        // ) {
        //   this.$router.push({
        //     name: "WarehouseDetail",
        //     params: { slug: this.user.defaultStoreAddress.addressId }
        //   });
        // }
      }
    },
    onFilterAddress(warehouseName) {
      if (!warehouseName || warehouseName === "") {
        this.warehouseList = this.warehouseListOriginal;
      } else {
        let filteredWarehouses = this.warehouseListOriginal.filter(
          warehouse =>
            warehouse.address.addressName
              .toLowerCase()
              .indexOf(warehouseName.toLowerCase()) > -1 ||
            warehouse.address.address1
              .toLowerCase()
              .indexOf(warehouseName.toLowerCase()) > -1 ||
            warehouse.address.city
              .toLowerCase()
              .indexOf(warehouseName.toLowerCase()) > -1
        );
        this.warehouseList = filteredWarehouses;
      }
    },
    onFilterWarehouses(warehouseList) {
      this.warehouseList = warehouseList;
      this.warehouseListOriginal = warehouseList;
    },
    async addressToSearchChanged(addressToSearch) {
      this.addressToSearch = addressToSearch;
      await this.findWarehouse();
      if (this.$refs.mapRef?.$mapObject) {
        this.$refs.mapRef.$mapObject.panTo({
          lat: addressToSearch.latitude,
          lng: addressToSearch.longitude
        });
      }
    },
    async findWarehouse() {
      let searchParams = {
        province_id: this.provinceId,
        parent_address_class_id: this.parentAddressClassId,
        region_id: this.regionId
      };
      if (this.addressToSearch) {
        searchParams["parent_latitude"] = this.addressToSearch.latitude;
        searchParams["parent_longitude"] = this.addressToSearch.longitude;
        searchParams["parent_distance"] = this.addressToSearch.distance;
      }
      if (this.selectedFilter) {
        searchParams["warehouse_class_id"] = this.selectedFilter;
      }
      let data = await AddressService.findWarehouse(searchParams);
      if (data.warehouses) {
        this.warehouse_key++;
      }
      this.warehouseList = data.warehouses;
      this.warehouseListOriginal = data.warehouses;
      this.filters = data.facets
        .filter(facet => facet.parameterName === "warehouse_class_id")[0]
        .values.filter(wc => this.filtersIds.includes(wc.id));
    },

    toggleInfoWindow(warehouse) {
      // this.mapRef.$mapObject.setCenter(this.infoWindowPos);
      this.infoWindowPos = {
        lat: warehouse.address.latitude,
        lng: warehouse.address.longitude
      };

      let phoneNumber = warehouse.metaData.warehouse_locator?.PHONE || " -- ";

      let infoWindowBtn = null;
      infoWindowBtn = `<a class='pl-0 v-btn v-btn--text primary--text v-size--small' target='system' href='https://www.google.it/maps/dir//${warehouse.name}@/${warehouse.address.latitude},${warehouse.address.longitude}'>
          COME ARRIVARE`;
      this.infoOptions.content = `<div id='infoWindowDialog' style='font-family: Open Sans, arial, sans-serif'>
          <h3 class='d-flex align-center mb-2' style='line-height: 1.2rem;'>
            ${warehouse.name || warehouse.address.addressName}
          </h3>
          <div class='mb-1'>
            ${warehouse.address.address1},${warehouse.address.addressNumber}
                ${warehouse.address.city} (${warehouse.address.province}) <br/>
            <strong>Telefono:</strong> ${phoneNumber} <br/>
          </div>
          ${infoWindowBtn}
        </div>`;

      //check if its the same marker that was selected if yes toggle
      if (
        this.selectedWarehouse &&
        this.selectedWarehouse.warehouseId == warehouse.warehouseId
      ) {
        this.infoWinOpen = !this.infoWinOpen;
      }

      //if different marker set infowindow to open and reset current marker index
      else {
        this.infoWinOpen = true;
        this.selectedWarehouse = warehouse;
      }
      if (this.infoWinOpen && this.$refs.mapRef?.$mapObject) {
        this.$refs.mapRef.$mapObject.panTo(this.infoWindowPos);
      }
    }
  },
  async mounted() {
    this.showListParsed = this.showList == "true" ? true : false;
    await this.findWarehouse();
    this.provinces = this.category?.children;
  }
};
</script>
